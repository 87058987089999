<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.local_execucao.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.local_execucao.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('LocalExecucao', 'Inserir')"
      :sem-filtro="!buscarPermissao('LocalExecucao', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('LocalExecucao', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      :disabled="!participanteLogadoConta"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="colunasTabela"
      :sem-acoes="!participanteLogadoConta"
      :mostrar-checkbox="participanteLogadoConta && buscarPermissao('LocalExecucao', 'Deletar')"
      sem-paginacao
    >
      <template
        v-if="participanteLogadoConta"
        v-slot:item.acoes="{ item }"
      >
        <dropdown-padrao
          v-if="buscarPermissao('LocalExecucao', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item

              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
      <template v-slot:item.flagEnvioMaterial="{ item }">
        <span :class="buscarDadosBoleanoEnum(item.item.flagEnvioMaterial)?.cor + '--text'">
          {{ buscarDadosBoleanoEnum(item.item.flagEnvioMaterial)?.text }}
        </span>
      </template>
      <template v-slot:item.instalacaoCliente="{ item }">
        <span :class="buscarDadosBoleanoEnum(item.item.instalacaoCliente)?.cor + '--text'">
          {{ buscarDadosBoleanoEnum(item.item.instalacaoCliente)?.text }}
        </span>
      </template>
      <template v-slot:item.flagAgendamento="{ item }">
        <span :class="buscarDadosBoleanoEnum(item.item.flagAgendamento)?.cor + '--text'">
          {{ buscarDadosBoleanoEnum(item.item.flagAgendamento)?.text }}
        </span>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService.js';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        quantidadeItens: 0,
      },
      filtroAplicado: { filter: '' },
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
    colunasTabela: function () {
      const colunas = [
        {
          value: 'acoes',
          text: '',
        },
        {
          value: 'nome',
          text: this.$t('modulos.local_execucao.tabela.nome'),
          sortable: true,
        },
        {
          value: 'instalacaoCliente',
          text: this.$t('modulos.local_execucao.tabela.instalacaoCliente'),
          sortable: true,
          formatter: (v) => helpers.BoleanoEnum.find((el) => el.value === v)?.text
        },
        {
          value: 'descricaoCertificado',
          text: this.$t('modulos.local_execucao.tabela.descricaoCertificado'),
          sortable: true,
        },
        {
          value: 'flagEnvioMaterial',
          text: this.$t('modulos.local_execucao.tabela.os_a_partir_de_envio_de_material'),
          sortable: true,
          formatter: (v) => helpers.BoleanoEnum.find((el) => el.value === v)?.text
        },
        {
          value: 'flagAgendamento',
          text: this.$t('modulos.local_execucao.tabela.os_a_partir_de_agendamento'),
          sortable: true,
          formatter: (v) => helpers.BoleanoEnum.find((el) => el.value === v)?.text
        }

      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.local_execucao.titulos.listagem')
    );

  },
  created(){
    helpers.redirecionarSemPermissao(this, 'LocalExecucao', 'Listar');
  },
  methods: {
    buscarDadosBoleanoEnum: function (valor) {
      return helpers.BoleanoEnum.find((el) => el.value === !!valor)
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function () {
      let parametros = {
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalExecucaoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(LocalExecucaoService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.local_estocagem.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'local-execucao-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'local-execucao-editar',
        params: { id: item.id },
      });
    },
  },
};
</script>
